<template>
  <div>
    <h1>Create a Watch Party</h1>
    <button @click="createWatchParty">Create Watch Party</button>

    <!-- Display the participant and judge links after the party is created -->
    <div v-if="partyCode">
      <p>Party Code: {{ partyCode }}</p>
      <p>Invite participants: <a :href="participantLink">{{ participantLink }}</a></p>
      <p>Send to judge: <a :href="judgeLink">{{ judgeLink }}</a></p>
    </div>
  </div>
</template>

<script>
import { createWatchPartyRecord } from '@/services/airtableService';

export default {
  name: 'WatchParty',
  data() {
    return {
      partyCode: '',
      participantLink: '', // Link for participants
      judgeLink: '', // Link for judge
    };
  },
  methods: {
    async createWatchParty() {
      try {
        const id = Math.floor(100000 + Math.random() * 900000).toString();
        const timestamp = new Date().toISOString();
        await createWatchPartyRecord(id, timestamp);
        this.partyCode = id;

        // Generate participant and judge links
        this.participantLink = `https://moviepick.me/party/${id}`;
        this.judgeLink = `https://moviepick.me/judgingscreen/${id}`;
      } catch (error) {
        console.error('Error creating watch party:', error);
      }
    },
  },
};
</script>