import axios from 'axios';

// Airtable API credentials
const API_KEY = 'patMVvkZ9rWbIVoOO.cceee1fb5b5f4c6c8cdcb65f3728745ec03a7837e3adf780ade91c3005540534';
const BASE_ID = 'appVdblcCdrrUBnOa';
const TABLE_NAME = 'parties';
const PARTICIPANTS_TABLE = 'participants';

// Create an Axios instance for Airtable API
const airtable = axios.create({
  baseURL: `https://api.airtable.com/v0/${BASE_ID}`,
  headers: {
    Authorization: `Bearer ${API_KEY}`,
  },
});

/**
 * Creates a new watch party in Airtable.
 * @param {string} id - The unique ID for the watch party.
 * @param {string} timestamp - The timestamp when the watch party was created.
 * @returns {Promise<Object>} - The created record from Airtable.
 */
export const createWatchPartyRecord = async (id, timestamp) => {
  try {
    const response = await airtable.post(`/${TABLE_NAME}`, {
      fields: {
        id,
        timestamp,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating watch party record:', error.response?.data || error.message);
    throw error;
  }
};

/**
 * Adds a new participant record to Airtable, including both the name and movie.
 * @param {string} name - The participant's name.
 * @param {string} movie - The movie recommendation.
 * @param {string} partyCode - The party code.
 * @returns {Promise<Object>} - The created participant record.
 */
export const addParticipantRecord = async (name, movie, partyCode) => {
  try {
    const response = await airtable.post(`/${PARTICIPANTS_TABLE}`, {
      fields: {
        name,
        movie,
        party_id: partyCode,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding participant record:', error.response?.data || error.message);
    throw error;
  }
};

/**
 * Fetches participants from Airtable for a given party code.
 * @param {string} partyCode - The party code.
 * @returns {Promise<Array>} - List of participant names and their movie recommendations.
 */
export const getParticipants = async (partyCode) => {
  try {
    const response = await airtable.get(`/${PARTICIPANTS_TABLE}`, {
      params: {
        filterByFormula: `{party_id} = '${partyCode}'`,
      },
    });
    const records = response.data.records;
    if (records.length > 0) {
      return records.map(record => ({
        id: record.id,
        name: record.fields.name,
        movie: record.fields.movie || '', // No role field anymore
      }));
    }
    return [];
  } catch (error) {
    console.error('Error fetching participants:', error.response?.data || error.message);
    throw error;
  }
};

/**
 * Updates a participant record with a movie recommendation.
 * @param {string} participantId - The participant ID.
 * @param {string} movie - The movie recommendation.
 * @returns {Promise<Object>} - The updated participant record.
 */
export const updateParticipantMovie = async (participantId, movie) => {
  try {
    const response = await airtable.patch(`/${PARTICIPANTS_TABLE}/${participantId}`, {
      fields: {
        movie,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating participant movie:', error.response?.data || error.message);
    throw error;
  }
};