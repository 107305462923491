<template>
  <div>
    <h1>Judge Voting Page</h1>
    <p v-if="movies.length === 0">No movies submitted yet.</p>
    <p v-else-if="movies.length === 1">1 movie submitted.</p>
    <p v-else>{{ movies.length }} movies submitted.</p>

    <!-- Show participants' names if there are movies submitted -->
    <ul v-if="movies.length > 0">
      <li v-for="(movie, index) in movies" :key="index">
        {{ movie.participantName }}
      </li>
    </ul>
  </div>
</template>

<script>
import { getParticipants } from '@/services/airtableService';

export default {
  name: 'JudgingScreen',
  data() {
    return {
      partyCode: this.$route.params.partyCode,
      movies: [], // List of movies submitted
      intervalId: null, // To store the interval ID for cleanup
    };
  },
  async created() {
    await this.fetchMovies();

    // Poll the server every 10 seconds for new submissions
    this.intervalId = setInterval(async () => {
      await this.fetchMovies();
    }, 10000); // Polling interval of 10 seconds
  },
  beforeUnmount() {
    // Clear the interval when the component is destroyed
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    async fetchMovies() {
      try {
        const participantsData = await getParticipants(this.partyCode);
        // Filter participants who have submitted a movie and store both movie and name
        this.movies = participantsData
          .filter(participant => participant.movie && participant.movie.trim() !== '')
          .map(participant => ({
            movieName: participant.movie,
            participantName: participant.name,
          }));
      } catch (error) {
        console.error('Error fetching participants/movies:', error);
      }
    },
  },
};
</script>

<style scoped>
  h1 {
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    margin-top: 10px;
  }

  ul {
    margin-top: 20px;
  }

  li {
    font-size: 16px;
  }
</style>