<template>
  <div>
    <h1>Watch Party</h1>
    <p>Party Code: {{ partyCode }}</p>

    <h2>Participants</h2>
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Movie</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="participant in participants" :key="participant.id">
          <td>{{ participant.name }}</td>
          <td>{{ participantStatus(participant) }}</td>
        </tr>
      </tbody>
    </table>

    <div v-if="!newParticipantSubmitted || !newMovieSubmitted">
      <input v-model="newParticipant" placeholder="Enter your name" @keypress.enter="submitParticipantInfo" />
      <input v-model="newMovie" placeholder="Enter your movie recommendation" @keypress.enter="submitParticipantInfo" />
      <button @click="submitParticipantInfo">Submit</button>
    </div>

    <!-- Disclaimer box for successful movie submission -->
    <div v-if="newParticipantSubmitted && newMovieSubmitted" class="disclaimer-box">
      <p>Your movie has been submitted!</p>
    </div>
  </div>
</template>

<script>
import { getParticipants, addParticipantRecord } from '@/services/airtableService';

export default {
  name: 'PartiScreen',
  data() {
    return {
      partyCode: '',
      participants: [],
      participantsMovies: {},
      newParticipant: '',
      newMovie: '',
      newParticipantSubmitted: false,
      newMovieSubmitted: false,
      intervalId: null,
    };
  },
  async created() {
    this.partyCode = this.$route.params.partyCode;

    const savedParticipant = localStorage.getItem('participantName');
    const savedMovie = localStorage.getItem('participantMovie');

    if (savedParticipant && savedMovie) {
      this.newParticipant = savedParticipant;
      this.newMovie = savedMovie;
      this.newParticipantSubmitted = true;
      this.newMovieSubmitted = true;
    }

    await this.fetchParticipants();

    this.intervalId = setInterval(async () => {
      await this.fetchParticipants();
    }, 10000);
  },
  beforeUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    async fetchParticipants() {
      try {
        const participantsData = await getParticipants(this.partyCode);
        this.participants = participantsData.map(participant => ({
          name: participant.name,
          movie: participant.movie || '',
        }));
        this.participantsMovies = participantsData.reduce((acc, participant) => {
          acc[participant.name] = participant.movie;
          return acc;
        }, {});
      } catch (error) {
        console.error('Error fetching participants:', error);
      }
    },
    async submitParticipantInfo() {
      if (this.newParticipant.trim() !== '' && this.newMovie.trim() !== '') {
        try {
          await addParticipantRecord(this.newParticipant, this.partyCode, this.newMovie);

          localStorage.setItem('participantName', this.newParticipant);
          localStorage.setItem('participantMovie', this.newMovie);

          this.participants.push({
            name: this.newParticipant,
            movie: this.newMovie,
          });

          this.newParticipantSubmitted = true;
          this.newMovieSubmitted = true;
        } catch (error) {
          console.error('Error submitting participant info:', error);
        }
      }
    },
    participantStatus(participant) {
      const movie = this.participantsMovies[participant.name];
      return movie ? 'Submitted' : 'Not Submitted Yet';
    },
  },
};
</script>

<style scoped>
.disclaimer-box {
  margin-top: 20px;
  padding: 10px;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  color: #155724;
}
</style>