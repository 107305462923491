import { createRouter, createWebHistory } from 'vue-router';
import WatchParty from '../components/WatchParty.vue';  // Import WatchParty.vue component
import PartiScreen from '../components/PartiScreen.vue';  // Import PartiScreen.vue component
import JudgingScreen from '../components/JudgingScreen.vue';  // Import JudgingScreen.vue component

const routes = [
  {
    path: '/',
    name: 'WatchParty',  // This corresponds to WatchParty.vue
    component: WatchParty,
  },
  {
    path: '/party/:partyCode',
    name: 'PartiScreen',  // This corresponds to PartiScreen.vue
    component: PartiScreen,
  },
  {
    path: '/judgingscreen/:partyCode',
    name: 'JudgingScreen',  // This corresponds to JudgingScreen.vue
    component: JudgingScreen,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;